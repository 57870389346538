// This is used for sending the module type along with sub module type to the image process to find the corresponding prefix to add in API
const ModulePrefix = {
  CustomTheme: 'CustomTheme',
  CustomCategory: 'CustomCategory',
  EventWebPage: 'EventWebPage',
  Program: 'Program',
  Notification: 'Notification',
  Event: 'Event',
  CustomBranding: 'CustomBranding',
};

export default Object.freeze(ModulePrefix);
