// This helps in storing the prefix for all combination of module and submodule type and return the corresponding prefix
import MediaPrefixViewModel from '@/entities/MediaPrefixViewModel';
import SubModulePrefix from '@/enums/SubModulePrefix';
import ModulePrefix from '@/enums/ModulePrefix';
export default class MediaPrefix {
  public prefixData: MediaPrefixViewModel[] = [];
  constructor() {
    this.prefixData.push({ModuleName: ModulePrefix.CustomTheme,SubModuleName: SubModulePrefix.IconImage, Prefix: 'dt_'});
    this.prefixData.push({ModuleName: ModulePrefix.CustomTheme,SubModuleName: SubModulePrefix.BackgroundImage, Prefix: 'dt_'});
    this.prefixData.push({ModuleName: ModulePrefix.CustomCategory,SubModuleName: SubModulePrefix.IconImage, Prefix: 'ccm_'});
    this.prefixData.push({ModuleName: ModulePrefix.CustomCategory,SubModuleName: SubModulePrefix.Multimedia, Prefix: 'ccm_'});
    this.prefixData.push({ModuleName: ModulePrefix.CustomCategory,SubModuleName: SubModulePrefix.Presentation, Prefix: 'cc_'});
    this.prefixData.push({ModuleName: ModulePrefix.EventWebPage,SubModuleName: SubModulePrefix.IconImage, Prefix: 'ewpmd_'});
    this.prefixData.push({ModuleName: ModulePrefix.EventWebPage,SubModuleName: SubModulePrefix.BackgroundImage, Prefix: 'ewpmd_'});
    this.prefixData.push({ModuleName: ModulePrefix.Program,SubModuleName: SubModulePrefix.IconImage, Prefix: 'pm_'});
    this.prefixData.push({ModuleName: ModulePrefix.Program,SubModuleName: SubModulePrefix.Multimedia, Prefix: 'pm_'});
    this.prefixData.push({ModuleName: ModulePrefix.Notification,SubModuleName: SubModulePrefix.Multimedia, Prefix: 'n_'});
    this.prefixData.push({ModuleName: ModulePrefix.Event,SubModuleName: SubModulePrefix.IconImage, Prefix: 'ai_'});
    this.prefixData.push({ModuleName: ModulePrefix.Event,SubModuleName: SubModulePrefix.BackgroundImage, Prefix: 'aia_'});
    this.prefixData.push({ModuleName: ModulePrefix.CustomBranding,SubModuleName: SubModulePrefix.IconImage, Prefix: 'cb_'});
  }
  public GetPrefix(moduleName: string, subModuleName: string) {
    const prefixObject = this.prefixData.find((val) => val.ModuleName === moduleName && val.SubModuleName === subModuleName);
    return  prefixObject !== undefined ? prefixObject.Prefix : '';
  }
}
